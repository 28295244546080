import * as yup from "yup";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TableHead,
	TextField,
} from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import React, { memo, useEffect, useMemo, useState } from "react";
import {
	appAPI,
	useCreateRecipeMutation,
	useLazyGetLineTypesByRecipeQuery,
	useLazyGetRecipeByIdQuery,
	useLazyGetRecipeLineLookuptableQuery,
	useLazyGetRecipeTypeConfigQuery,
	useLazyGetRecipeTypesQuery,
	useUpdateRecipeMutation,
} from "../../../services/API/appAPI";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Load from "../Common/Loader";
import Regex from "../Common/Regex";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import fullData from "../DashBoard/Charts/data.json";
import { styled } from "@mui/material/styles";
import { successModal } from "../Common/Modal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// import { table } from "./constants";

//import TableHead from "@mui/material/TableHead";
const DEFAULT_RECIPE_TYPE = "light"

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

function EditUser({ viewOnly = false }) {
	const { recipeId = "" } = useParams();
	const { state: stateCopy = {} } = useLocation();
	const dispatch = useDispatch()

	const [getRecipeTypes, recipeTypesResult] = useLazyGetRecipeTypesQuery();
	const [getLineTypesByRecipe, lineTypesResult] =
		useLazyGetLineTypesByRecipeQuery();
	const [getRecipeTypeConfig, recipeTypeConfigResult] =
		useLazyGetRecipeTypeConfigQuery();
	const [createRecipe, { isLoading: loadcreate, isFetching: fetchcreate }] =
		useCreateRecipeMutation();

	const [getRecipeById, recipeResult] = useLazyGetRecipeByIdQuery();

	const [updateRecipe, { isLoading: loadUpdate, isFetching: fetchUpdate }] =
		useUpdateRecipeMutation();

	/*const [
		getLookUpTable,
		{
			currentData: table,
			isLoading: loadlookup,
			isFetching: fetchlookup,
			isUninitialized: uninitlookup,
		},
	] = useLazyGetRecipeLineLookuptableQuery();*/
	
	const [table, setTable] = useState({})

	// const [dependents, setDependents] = useState({
	// 	settlementsPerDay: "",
	// 	settlementPeriod: "",
	// 	defaultStartSettlement: "",
	// });

	const [settlements, setSettlements] = useState([]);
	const [lookupTables, setLookupTables] = useState([]);
	const [totals, setTotals] = useState({ moles: 0, watts: 0, totalPrice: 0 });
	const {
		isLoading: loadRecipeTypes,
		isFetching: fetchRecipeTypes,
		currentData: recipeTypes = [],
	} = recipeTypesResult;
	const {
		isLoading: loadLineTypes,
		isFetching: fetchLineTypes,
		currentData: lineTypes = [],
	} = lineTypesResult;
	const {
		isLoading: loadRecipeTypeConfig,
		isFetching: fetchRecipeTypeConfig,
		currentData: config = {},
		isUninitialized: unInitConfig,
	} = recipeTypeConfigResult;
	const {
		isLoading: loadRecipe,
		isFetching: fetchRecipe,
		currentData: recipe,
		isUninitialized: unInitRecipe,
	} = recipeResult;

	function getConfigValues(config) {
		const n = {};
		Object.keys(config).forEach((key) => {
			const { value = "" } = config[key];
			n[key] = value;
		});
		return n;
	}
	function getTotals(arr, _table = table) {
		// console.log(arr);
		return arr.reduce(
			(acc, curr, index) => {
				const moles = acc.moles + getMoleByIntensity(curr, _table);
				const watts = acc.watts + getWattsByIntensity(curr, _table);
				const totalPrice =
					watts * Number(fullData.recipe_data.prices[index]?.price ?? 0);
				return { moles, watts, totalPrice };
			},
			{ moles: 0, watts: 0, totalPrice: 0 }
		);
	}

	function convertToTitleCase(str) {
		return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
			return str.toUpperCase();
		});
	}
	function convertToReadable(str) {
		return convertToTitleCase(str.replace(/([A-Z])/g, " $1"));
	}

	function getMoleByIntensity(intensity, _table = table) {
		const val = Number(
			_table?.[(Number(intensity ?? 0) / 100).toFixed(2)]?.moles ?? 0
		);
		return val;
	}
	function getWattsByIntensity(intensity, _table = table) {
		return Number(
			_table?.[(Number(intensity ?? 0) / 100).toFixed(2)]?.watts ?? 0
		);
	}

	async function getLookupTables(recipeType) {
		const {data: lookupTables} = await dispatch(appAPI.endpoints.getLookupTables.initiate({recipeType}))
		console.log(`lookupTables : ${JSON.stringify(lookupTables)}`)
		return lookupTables
	}

	useEffect(() => {
		async function getFormDetails() {
			let currentRecipe;
			let _table;
			if (recipeId.length > 0) {
				const { data: _currentRecipe } = await getRecipeById({ recipeId });
				/**{
    "slot": 2,
    "settlementType": "dawn",
    "values": {
        "intensity": 0
    },
    "_id": "65d812c94cc22e45e30adc9e"
} */
				currentRecipe = _currentRecipe;
			}
			currentRecipe =
				[...Object.keys(stateCopy ?? {})]?.length > 0
					? { ...stateCopy, name: "" }
					: currentRecipe;
			
			let {type: recipeType = '', config: {lookupTableName = ''} = {}} = currentRecipe || {}
			const { data: recipeTypes = [] } = await getRecipeTypes();
			if (recipeType.length === 0) {
				if(recipeTypes.length > 0) {
					recipeType = recipeTypes[0]
				} else {
					recipeType = DEFAULT_RECIPE_TYPE
				}
			}
			const _lookupTables = await getLookupTables(recipeType) || []
			setLookupTables(_lookupTables)
			var { settlementsPerDay, settlementPeriod, defaultStartSettlement } = {};
				const { data: lineTypes = [] } = await getLineTypesByRecipe({
					recipeType,
				});
				/*const { data: _Currenttable } = await getLookUpTable({
					recipeType: recipeTypes?.[0] ?? "",
					lineType: lineTypes?.[0] ?? "",
				});*/
				const { data: config } = await getRecipeTypeConfig({
					recipeType,
				});
				var { settlementsPerDay, settlementPeriod, defaultStartSettlement } =
					currentRecipe?.config ?? getConfigValues(config);
				customeSetDependents({
					settlementsPerDay,
					settlementPeriod,
					defaultStartSettlement,
				});
			const currentSettlements =
				currentRecipe?.settlements ?? stateCopy?.settlements ?? [];

			// console.log("asdfghjkl", current);
			const res =
				currentSettlements?.map((settlement, index) => {
					const {
						settlementType,
						values: { intensity },
					} = settlement;
					return {
						category: settlementType,
						intensity: intensity * 100,
						settlementPeriod: index,
					};
				}) ?? [];
			if (res.length > 0) {
				setSettlements(
					getSettlementsArray(
						{
							settlementsPerDay,
							settlementPeriod,
							defaultStartSettlement,
						},
						false,
						res
					)
				);
				_table = _lookupTables.find(({name}) => name === lookupTableName)?.table || {};
				setTable(_table)
				const totals = getTotals(
					res.map(({ intensity = 0 }) => intensity),
					_table
				);
				setTotals(totals);
			}
		}
		getFormDetails();
	}, []);

	// console.log(recipe ? recipe : { name: "nodata" });

	const navigate = useNavigate();

	function customeSetDependents(
		// {
		// 	settlementsPerDay = "",
		// 	settlementPeriod = "",
		// 	defaultStartSettlement = "",
		// 	...rest
		// }
		obj,
		rearrangeOnly = false
	) {
		// setDependents({
		// 	settlementsPerDay,
		// 	settlementPeriod,
		// 	defaultStartSettlement,
		// });
		setSettlements(getSettlementsArray(obj, rearrangeOnly));
	}

	function getSettlementsArray(obj, rearrangeOnly = false, fill = []) {
		const {
			settlementsPerDay,
			settlementPeriod,
			defaultStartSettlement,
			settlementPeriodChange = false,
			settlementsPerDayChanged = false,
			formik,
		} = obj;
		let currentSettlementsPerDay = settlementsPerDay;
		let currentDefaultStartSettlement = defaultStartSettlement;
		if (rearrangeOnly && settlements?.length > 0) {
			const index = settlements?.findIndex(({ settlementPeriod }) =>
				settlementPeriod?.endsWith(` ${currentDefaultStartSettlement}`)
			);
			let stop = false;
			if (index >= 0) {
				return settlements
					?.slice(index)
					?.concat(settlements.slice(0, index))
					?.map((obj, index) => {
						const { settlementPeriod = "" } = obj ?? {};
						if (settlementPeriod.endsWith(" 1") && index !== 0) stop = true;
						if (!stop) {
							obj.settlementPeriod = settlementPeriod.replace(
								"next",
								"current"
							);
						} else {
							obj.settlementPeriod = settlementPeriod.replace(
								"current",
								"next"
							);
						}
						return obj;
					});
			}
			return settlements;
		}

		if (
			settlementPeriodChange ||
			(!settlementPeriodChange &&
				Math.floor(86400 / Number(settlementPeriod)) < settlementsPerDay)
		) {
			currentSettlementsPerDay = Math.floor(86400 / Number(settlementPeriod));
			currentDefaultStartSettlement = currentSettlementsPerDay - 1;
			// setDependents({
			// 	...dependents,
			// 	settlementsPerDay: currentSettlementsPerDay,
			// 	defaultStartSettlement: currentDefaultStartSettlement,
			// });
			formik?.setFieldValue(
				"config.settlementsPerDay",
				currentSettlementsPerDay
			);
			formik?.setFieldValue(
				"config.defaultStartSettlement",
				currentDefaultStartSettlement
			);
		}

		if (settlementsPerDayChanged) {
			currentDefaultStartSettlement = currentSettlementsPerDay - 1;
			formik?.setFieldValue(
				"config.defaultStartSettlement",
				currentDefaultStartSettlement
			);
		}

		const startTime = new Date(
			new Date("2024-02-22T00:00:00.000").getTime() +
				Number(settlementPeriod) * Number(currentDefaultStartSettlement) * 1000
		);

		const startDate = startTime.getDate();
		let s = Number(currentDefaultStartSettlement);
		let dayChanged = false;
		const final = Array.from(
			{ length: Number(currentSettlementsPerDay) },
			(_, index) => {
				const d = new Date(
					startTime.getTime() +
						Number(settlementPeriod) * Number(index - 1) * 1000
				);
				const date = d.getDate();
				let str = "";
				if (date == startDate) {
					str = "current Day";
				} else {
					str = "next Day";
					if (!dayChanged) {
						s = 1;
					}
					dayChanged = true;
				}
				str += ` ${s}`;
				s++;
				return {
					category: fill?.[index]?.category ?? "dawn",
					intensity: fill?.[index]?.intensity ?? "0",
					settlementPeriod: str,
				};
			}
		);

		return final;
	}

	// const settlements = useMemo(getSettlementsArray, [dependents]);

	// const {
	// 	settlementPeriod,
	// 	settlementsPerDay,
	// 	defaultStartSettlement,
	// 	totalMolesPerDayMinThreshold,
	// 	totalMolesPerDayMaxThreshold,
	// 	moleChangePerDayMin,
	// 	moleChangePerDayMax,
	// 	lightIntensityPerSettlementPeriodMin,
	// 	lightIntensityPerSettlementPeriodMax,
	// 	intensityChangePerSettlementPeriodMin,
	// 	intensityChangePerSettlementPeriodMax,
	// 	lineType,
	// 	lookupTableName,
	// } = config;

	const settlementPeriodWidth = "120px";
	const categoryWidth = "110px";

	const settlementsDiv = useMemo(
		() => (
			<TableContainer variant='outlined'>
				<Table
					sx={{ minWidth: 650 }}
					aria-label='recipes create'
					className=' data-table'>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: settlementPeriodWidth }}>
								Settlement Period
							</TableCell>
							<TableCell sx={{ width: categoryWidth }}>Category</TableCell>
							<TableCell sx={{ width: categoryWidth }}>
								Intensity {"(%)"}
							</TableCell>
							<TableCell>Moles</TableCell>
							<TableCell>Wattage</TableCell>
							<TableCell>EPEX Unit Price</TableCell>
							<TableCell>Calculated Price {"(Wattage * Unit Price)"}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{settlements?.map(({ settlementPeriod = "" }, index) => (
							<StyledTableRow key={index}>
								<TableCell
									sx={{
										textTransform: "capitalize",
										width: settlementPeriodWidth,
									}}>
									{settlementPeriod}
								</TableCell>
								<TableCell sx={{ width: categoryWidth }}>
									<FormControl
										size='small'
										sx={{ minWidth: "95px" }}
										disabled={viewOnly}>
										<Select
											value={settlements[index]?.category}
											onChange={(event) => {
												const _settlements = [...settlements];
												_settlements[index].category = event.target.value;
												setSettlements(_settlements);
											}}>
											<MenuItem value={"dawn"}>Dawn</MenuItem>
											<MenuItem value={"day"}>Day</MenuItem>
											<MenuItem value={"dusk"}>Dusk</MenuItem>
										</Select>
									</FormControl>
								</TableCell>
								<TableCell sx={{ width: categoryWidth }}>
									<TextField
										type={"number"}
										size='small'
										value={
											settlements[index]?.intensity?.toString()
												? Math.round(settlements[index]?.intensity ?? 0)
												: ""
										}
										// name={`settlements[${index}].intensity`}
										// onChange={formik.handleChange}
										// onBlur={formik.handleBlur}
										inputProps={{
											min: 0, // Set the minimum value
											max: 100, // Set the maximum value
											step: 1, // Set the step size (optional)
											// It is not stoping if we edit the text field
										}}
										disabled={viewOnly}
										sx={{ width: "100px" }}
										onChange={(event) => {
											const val = Number(event.target.value);
											if (val < 0 || val > 100) return;

											const addmole = [
												Number(table?.[(val / 100).toFixed(2)]?.moles ?? 0),
												Number(
													table?.[
														(
															Number(settlements[index]?.intensity ?? 0) / 100
														).toFixed(2)
													]?.moles ?? 0
												),
											];

											const addWatts = [
												Number(table?.[(val / 100).toFixed(2)]?.watts ?? 0),
												Number(
													table?.[
														(
															Number(settlements[index]?.intensity ?? 0) / 100
														).toFixed(2)
													]?.watts ?? 0
												),
											];
											const addTotal = [
												addWatts[0] *
													Number(
														fullData.recipe_data.prices[index]?.price ?? 0
													),
												addWatts[1] *
													Number(
														fullData.recipe_data.prices[index]?.price ?? 0
													),
											];

											setTotals({
												moles: totals.moles + addmole[0] - addmole[1],
												watts: totals.watts + addWatts[0] - addWatts[1],
												totalPrice:
													totals.totalPrice + addTotal[0] - addTotal[1],
											});
											const _settlements = [...settlements];
											_settlements[index].intensity = event.target.value;

											setSettlements(_settlements);
										}}
									/>
								</TableCell>
								<TableCell>
									{Number(
										table?.[
											(
												Number(settlements[index]?.intensity ?? 0) / 100
											).toFixed(2)
										]?.moles ?? 0
									)?.toFixed(4)}
								</TableCell>
								<TableCell>
									{Number(
										table?.[
											(
												Number(settlements[index]?.intensity ?? 0) / 100
											).toFixed(2)
										]?.watts ?? 0
									)?.toFixed(4)}
								</TableCell>
								<TableCell>
									{Number(
										fullData.recipe_data.prices[index]?.price ?? 0
									)?.toFixed(4)}
								</TableCell>
								<TableCell>
									{Number(
										(table?.[
											(
												Number(settlements[index]?.intensity ?? 0) / 100
											).toFixed(2)
										]?.watts ?? 0) *
											fullData.recipe_data.prices[index]?.price ?? 0
									)?.toFixed(4)}
								</TableCell>
							</StyledTableRow>
						))}
						<StyledTableRow>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>{totals.moles?.toFixed(4)}</TableCell>
							<TableCell>{totals.watts?.toFixed(4)}</TableCell>
							<TableCell></TableCell>
							<TableCell>{totals.totalPrice?.toFixed(4)}</TableCell>
						</StyledTableRow>
					</TableBody>
				</Table>
			</TableContainer>
		),
		[settlements, viewOnly, table]
	);

	if (
		loadRecipeTypes ||
		fetchRecipeTypes ||
		loadRecipeTypeConfig ||
		fetchRecipeTypeConfig ||
		loadRecipe ||
		fetchRecipe ||
		loadLineTypes ||
		fetchLineTypes ||
		(recipeId && unInitRecipe)
	)
		return <Load />;

	// console.log(table);

	const initFormik = recipe?.config
		? recipe
		: [...Object.keys(stateCopy ?? {})]?.length > 0
		? { ...stateCopy, name: "" }
		: {
				name: "",
				type: recipeTypes?.[0] ?? "",
				config: getConfigValues(config),
		  };

	return (
		<>
			<Button
				variant='contained'
				className='btn'
				startIcon={<ChevronLeftIcon />}
				onClick={() => navigate("/recipes")}>
				BACK
			</Button>
			{/* <h3>{recipe.id ? "Edit Recipe" : "Create Recipe"}</h3> */}
			{loadcreate || fetchcreate || loadUpdate || fetchUpdate ? <Load /> : null}
			<Formik
				initialValues={initFormik}
				validationSchema={yup.object({
					name: yup
						.string()
						.matches(Regex.recipename, "Invalid Recipe Name")
						.required("Recipe Name is required*"),
				})}
				onSubmit={async (values) => {
					const _settlements = settlements?.map(
						({ category, intensity }, index) => {
							return {
								slot: index + 1,
								settlementType: category,
								values: { intensity: Number(intensity) / 100 },
							};
						}
					);
					// console.log({ ...values, settlements: _settlements });
					if (recipeId) {
						const { error } = await updateRecipe({
							recipeId,
							body: { ...values, settlements: _settlements },
						});
						if (error == undefined) {
							successModal({
								message: `Recipe: ${values?.name} updated successfully`,
							}).then(() => {
								navigate("/recipes");
							});
						}
					} else {
						const { error } = await createRecipe({
							...values,
							settlements: _settlements,
						});
						if (error == undefined) {
							successModal({
								message: `Recipe: ${values?.name} created successfully`,
							}).then(() => {
								navigate("/recipes");
							});
						}
					}
				}}>
				{(formik) => {
					return (
						<Card variant='outlined' className='recipe-edit card'>
							<CardContent>
								<table aria-label='recipe edit'>
									<tbody>
										<tr className='row'>
											<td>Recipe Name</td>
											<td>
												<FormControl
													className='input'
													disabled={recipe || viewOnly}>
													<InputLabel
														id='recipe-name-label'
														htmlFor='outlined-adornment-recipe-name'
														size='small'>
														Recipe Name
													</InputLabel>
													<OutlinedInput
														id='outlined-adornment-recipe-name'
														type={"text"}
														label='Recipe Name'
														size='small'
														aria-describedby='recipe-name-label'
														name='name'
														value={formik.values.name}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														sx={{ minWidth: "320px" }}
													/>
													{formik.touched?.name && formik.errors.name ? (
														<span className='error'>{formik.errors.name}</span>
													) : null}
												</FormControl>
											</td>
										</tr>
										{/* <tr className='row'>
											<td>Location</td>
											<td>
												<FormControl sx={{ width: 300 }} size='small'>
													<InputLabel id='multiple-checkbox-label'>
														Location
													</InputLabel>
													<Select
														labelId='multiple-checkbox-label'
														id='multiple-checkbox'
														multiple
														value={locations}
														onChange={onSelectLocation}
														input={<OutlinedInput label='Location' />}
														renderValue={(selected) => selected.join(", ")}>
														{locationOptions.map((location) => (
															<MenuItem key={location} value={location}>
																<Checkbox
																	checked={locations.indexOf(location) > -1}
																/>
																<ListItemText primary={location} />
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</td>
										</tr> */}
										<tr className='row'>
											<td>Recipe Type</td>
											<td>
												<FormControl
													size='small'
													sx={{ width: '320px' }}
													disabled={recipe || viewOnly}>
													<InputLabel id='select-helper-label'>Type</InputLabel>
													<Select
														labelId='select-helper-label'
														label='Type'
														name='type'
														value={formik.values.type}
														onChange={async (event) => {
															formik.handleChange(event);
															await getLookupTables(event.target.value)
															setLookupTables(_lookupTables)
														}}
														onBlur={formik.handleBlur}>
														{recipeTypes.map((type) => (
															<MenuItem value={type} key={type}>
																{type}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</td>
										</tr>
										<tr className='row'>
											<td>Light Type</td>
											<td>
												<FormControl
													size='small'
													sx={{ width: "320px" }}
													disabled={viewOnly}>
													<InputLabel id='select-helper-label'>Type</InputLabel>
													<Select
														labelId='select-helper-label'
														label='Type'
														value={
															typeof formik.values?.config?.lineType == "string"
																? formik.values?.config?.lineType
																: formik.values?.config?.lineType?.value
														}
														name='config.lineType'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}>
														{lineTypes.map((type) => (
															<MenuItem value={type} key={type}>
																{type}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</td>
										</tr>
										<tr className='row'>
											<td>Lookup Name</td>
											<td>
												<FormControl
													size='small'
													sx={{ width: "320px" }}
													disabled={viewOnly}>
													<InputLabel id='select-helper-label'>Lookup Name</InputLabel>
													<Select
														labelId='select-helper-label'
														label='Lookup Name'
														value={	
															typeof formik.values?.config?.lookupTableName == "string"
																? formik.values?.config?.lookupTableName
																: formik.values?.config?.lookupTableName?.value
														}
														name='config.lookupTableName'
														onChange={(event) => {
															formik.handleChange(event);
															console.log(lookupTables)
															const _table = lookupTables.find(({name}) => name === event.target.value)?.table || {};
															console.log(_table)
															const res =
																settlements?.map((settlement, index) => {
																const {
																	category,
																	intensity,
																} = settlement;
																return {
																	category,
																	intensity,
																	settlementPeriod: index,
																};
															}) ?? [];
															const totals = getTotals(
																res.map(({ intensity = 0 }) => intensity),
																_table
															);
															setTable(_table);
															setTotals(totals);
														}}
														onBlur={formik.handleBlur}>
														{(lookupTables || []).map(({name}) => (
															<MenuItem value={name} key={name}>
																{name}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</td>
										</tr>
									</tbody>
								</table>
								{[...Object.keys(config)].length > 0 ? (
									<Card variant='outlined' className='recipe-constraints'>
										<Accordion>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<h4
													style={{
														textTransform: "capitalize",
														padding: 0,
														margin: 0,
													}}>
													{formik.values.type} Recipe Constraints
												</h4>
											</AccordionSummary>
											<AccordionDetails>
												<Box
													className='form-body'
													sx={{
														display: "flex",
														flexFlow: "wrap",
														gap: "1rem",
														padding: "1.5rem",
														justifyContent: "space-between",
														"& label": {
															backgroundColor: "white",
															padding: "0 4px",
														},
													}}>
													{[...Object.keys(config)].map((key) => {
														const ignore = [
															"lineType",
															"lookupTableName",
															"defaultStartSettlement",
														];
														if (ignore.some((val) => val === key)) return null;
														return (
															<FormControl
																className='input'
																key={key}
																sx={{ width: "48%" }}
																disabled={viewOnly}>
																<InputLabel
																	id='recipe-name-label'
																	htmlFor='outlined-adornment-recipe-name'
																	size='small'>
																	{config?.[key]?.label}
																</InputLabel>
																<OutlinedInput
																	id='outlined-adornment-recipe-name'
																	type={"number"}
																	label='Recipe Name'
																	inputProps={{
																		min: 0,
																		max:
																			key == "settlementsPerDay"
																				? 86400 /
																				  Number(
																						formik.values.config
																							.settlementPeriod
																				  )
																				: Number.POSITIVE_INFINITY,
																	}}
																	size='small'
																	aria-describedby='recipe-name-label'
																	name={"config." + key}
																	value={formik.values.config?.[key]}
																	onChange={(event) => {
																		formik.handleChange(event);
																		const {
																			settlementsPerDay = "",
																			settlementPeriod = "",
																			defaultStartSettlement = "",
																		} = formik.values.config;
																		if (key == "settlementsPerDay") {
																			customeSetDependents({
																				settlementPeriod,
																				defaultStartSettlement,
																				settlementsPerDay: event.target.value,
																				settlementsPerDayChanged: true,
																				formik,
																			});
																		}
																		if (key == "settlementPeriod") {
																			customeSetDependents({
																				settlementsPerDay,
																				defaultStartSettlement,
																				settlementPeriod: event.target.value,
																				settlementPeriodChange: true,
																				formik,
																			});
																		}
																	}}
																	onBlur={formik.handleBlur}
																/>
															</FormControl>
														);
													})}
												</Box>
											</AccordionDetails>
										</Accordion>
									</Card>
								) : null}
								{[...Object.keys(config)].length > 0 ? (
									<>
										<table>
											<tbody>
												<tr>
													<td>Start Settlement Period:</td>
													<td>
														<FormControl
															sx={{ width: "100px" }}
															size='small'
															disabled={viewOnly}>
															<Select
																name='config.defaultStartSettlement'
																value={
																	formik.values.config?.defaultStartSettlement
																}
																onChange={(event) => {
																	formik.handleChange(event);
																	const {
																		settlementsPerDay = "",
																		settlementPeriod = "",
																		// defaultStartSettlement = "",
																	} = formik.values.config;
																	customeSetDependents(
																		{
																			settlementsPerDay,
																			settlementPeriod,
																			defaultStartSettlement:
																				event.target.value,
																		},
																		true
																	);
																}}
																onBlur={formik.handleBlur}
																sx={{ width: "100px" }}>
																{Array.from(
																	{
																		length: settlements?.length ?? 0,
																	},
																	(_, i) => i + 1
																).map((val) => (
																	<MenuItem value={val} key={val}>
																		{val}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
													</td>
												</tr>
												<tr>
													<td>End Settlement Period:</td>
													<td style={{ textTransform: "capitalize" }}>
														{settlements?.length > 0
															? settlements[settlements.length - 1]
																	.settlementPeriod
															: ""}
													</td>
												</tr>
											</tbody>
										</table>
										{settlementsDiv}
										{/* <div>
											<p>Total Intensity: 50.50</p>
											<p>Total Moles: 19.333</p>
											<p>Total Price based on latest prices: 121.2704</p>
										</div> */}
									</>
								) : null}

								<CardActions className='actions'>
									{viewOnly ? (
										<Button
											variant='contained'
											className='btn'
											onClick={() =>
												navigate("/recipes/" + recipe.id + "/edit")
											}>
											EDIT
										</Button>
									) : null}
									{!viewOnly && (
										<>
											<Button
												variant='contained'
												className='btn'
												startIcon={<KeyboardArrowUpIcon />}
												onClick={formik.handleSubmit}
												disabled={viewOnly}>
												SUBMIT
											</Button>
											<Button
												variant='outlined'
												// component={Link}
												// to={"/recipes"}
												// className='btn'
												startIcon={<CancelOutlinedIcon />}
												onClick={() => navigate("/recipes")}>
												CANCEL
											</Button>
										</>
									)}
								</CardActions>
							</CardContent>
						</Card>
					);
				}}
			</Formik>
		</>
	);
}

export default memo(EditUser);
