import { ChevronLeftOutlined, DownloadOutlined } from "@mui/icons-material";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appAPI } from "../../../services/API/appAPI";
import { useDispatch } from "react-redux";
import { errorModal, successModal } from "../Common/Modal";

// POST /resources/recipe/:type/lookupTables

const DEFAULT_RECIPE_TYPE = 'light'
const DEFAULT_LINE_TYPE = "double"

export default function ManageLookups() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [lookups, setLookups] = useState([])
    const [selectedFile, setSelectedFile] = useState(null);
    const [lname, setLName] = useState('')
    const [desc, setDesc] = useState('')

    const handleFileChange = (event) => { setSelectedFile(event.target.files[0]); };

    const convertCSVToJSON = (csvData) => {
        let ret = {}
        const lines = csvData.split('\n')
        if (lines.length === 0) {
            throw Error('CSV File Empty')
        }
        const header = lines[0]
        const parts = header.split(',')
        if (parts.length !== 3) {
            throw Error("Incorrect CSV format")
        }

        if (parts[0] !== 'intensity' || parts[1] !== 'moles' || parts[2] !== 'watts') {
            throw Error("Incorrect CSV format")
        }

        const dataLines = lines.slice(1)
        const values = dataLines.map(line => {
            const ps = line.split(',')
            if (ps.length !== 3) {
                throw Error("Incorrect CSV format")
            }
            const intensity = parseFloat(ps[0])
            const moles = parseFloat(ps[1])
            const watts = parseFloat(ps[2])

            if (isNaN(intensity) || isNaN(moles) || isNaN(watts)) {
                throw Error("Invalid values in CSV")
            }
            const wholeIntensity = parseInt(intensity * 100)
            if (wholeIntensity < 0 || wholeIntensity > 100) {
                throw Error("Invalid intensity value in CSV")
            }
            return [intensity.toFixed(2), moles, watts]
        })
        ret = values.reduce((acc, cur, idx) => {
            const [key, moles, watts] = cur
            acc[key] = { moles, watts }
            return acc
        }, {})

        const keys = Object.keys(ret)
        const hasMissing = keys.some((val, idx) => {
            return val !== (idx / 100).toFixed(2)
        })

        if (keys.length != 101 || hasMissing) {
            throw Error("Missing values in CSV")
        }

        return ret
    }

    const handleUpload = () => {
        if (!selectedFile || selectedFile.length === 0 || lname.length === 0 || desc.length === 0) {
            errorModal({ message: "Please enter all the mandatory values *" })
            return
        }

        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = e.target.result
            try {
                const jsonData = convertCSVToJSON(text)
                const request = {
                    name: lname,
                    description: desc,
                    table: jsonData,
                    lineType: DEFAULT_LINE_TYPE,
                    recipeType: DEFAULT_RECIPE_TYPE
                }
                const response = await dispatch(appAPI.endpoints.uploadLookupTable.initiate(request))
                if (response.error) {
                    const { error: { data: { error = '' } = {} } = {} } = response
                    throw Error(error)
                }
                successModal({ message: `Lookup (${lname}) uploaded successfully` }).then((result) => {
                    getLookups()
                });
            } catch (e) {
                errorModal({ message: e.message })
            }
        }
        reader.readAsText(selectedFile)
    };

    const getLookups = async () => {
        const { data: lookupTables } = await dispatch(appAPI.endpoints.getLookupTables.initiate({ recipeType: DEFAULT_RECIPE_TYPE }, { forceRefetch: true }))
        setLookups(lookupTables)
    }

    useEffect(() => {
        getLookups();
    }, [])

    function downloadJSON(data, filename) {
        const dataString = Object.keys(data).map((k) => [k, ...(Object.values(data[k]))].join(',')).join('\n')
        const dataStr = `intensity,moles,watts\n${dataString}`
        const blob = new Blob([dataStr], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${filename}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <>
            <Button
                variant='contained'
                className='btn'
                startIcon={<ChevronLeftOutlined />}
                onClick={() => navigate("/recipes")}>
                BACK
            </Button>
            <form>
                <table style={{ border: '1px solid gray', padding: '16px', borderRadius: '8px' }}>
                    <tr>
                        <td>
                            Name*
                        </td>
                        <td>
                            <input style={{ margin: '8px' }} value={lname} onChange={event => setLName(event.target.value)} type="text"></input>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Description*
                        </td>
                        <td>
                            <input style={{ margin: '8px' }} value={desc} onChange={event => setDesc(event.target.value)} type="text"></input>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Choose Lookup CSV *
                        </td>
                        <td>
                            <input style={{ margin: '8px' }} onChange={handleFileChange} type="file" accept=".csv"></input>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} align="center">
                            <Button sx={{ width: '150px' }} style={{ margin: '8px' }} variant='contained' className='btn' onClick={handleUpload}>Upload</Button>
                        </td>
                    </tr>
                </table>
            </form>
            <TableContainer className='card data-table'>
                <Table sx={{ width: '60%' }} aria-label='lookups table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>S. No.</TableCell>
                            <TableCell>Lookup Name</TableCell>
                            <TableCell sx={{ width: "60px" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {lookups?.map(({ name, table }, index) => (
                            <TableRow key={name} className='row'>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{name}</TableCell>
                                <TableCell sx={{ width: "60px", padding: "0.2rem !important" }} align="center">
                                    <Button onClick={() => downloadJSON(table, `${name}.csv`)}><DownloadOutlined /></Button>
                                </TableCell>
                            </TableRow>
                        ))}
                        {lookups.length == 0 ? (
                            <TableRow className="row">
                                <TableCell colSpan={2} align="center">No Data Found</TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}