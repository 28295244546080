import * as React from "react";

import { Button, ButtonGroup, Divider, Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { deleteModal, successModal } from "../Common/Modal";
import {
	useDeleteRecipeMutation,
	useLazyGetRecipesQuery,
} from "../../../services/API/appAPI";

import AddIcon from "@mui/icons-material/Add";
import Checkbox from "@mui/material/Checkbox";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Loader from "../Common/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatDate } from "../Common/utilityFns";
import usePagination from "../Common/usePagination";

/**
 * TODO
 * 	- add [apply to niagara] btn, display if the recipe is in live and display pop up when clicked
 * 	- recipe confirmation pop up
 */

// export const recipes = [
// 	{
// 		id: "1",
// 		action: ["EDIT", "DELETE"],
// 		active: true,
// 		name: "standard",
// 		type: "Light",
// 		isBase: true,
// 		location: "Room 1",
// 		created_date: "21-12-2023",
// 		modified_date: "21-12-2023",
// 		last_proceded_date: "12-12-2023 12:25",
// 	},
// 	{
// 		id: "2",
// 		action: ["EDIT", "DELETE"],
// 		active: true,
// 		name: "summer",
// 		type: "Light",
// 		isBase: false,
// 		location: "Room 2",
// 		created_date: "21-12-2023",
// 		modified_date: "21-12-2023",
// 		last_proceded_date: "12-12-2023 12:25",
// 	},
// 	{
// 		id: "3",
// 		action: ["EDIT", "DELETE"],
// 		active: true,
// 		name: "winter",
// 		type: "Light",
// 		isBase: false,
// 		location: "Room 3",
// 		created_date: "21-12-2023",
// 		modified_date: "21-12-2023",
// 		last_proceded_date: "12-12-2023 12:25",
// 	},
// 	{
// 		id: "4",
// 		action: ["EDIT", "DELETE"],
// 		active: true,
// 		name: "standard",
// 		type: "Light",
// 		isBase: false,
// 		location: "Room 4",
// 		created_date: "21-12-2023",
// 		modified_date: "21-12-2023",
// 		last_proceded_date: "12-12-2023 12:25",
// 	},
// ];

/**{
            "name": "test.recipe2",
            "rooms": [ // Join with , and show under location column
                {
                    "id": "room_a5f0b1a1-65c4-47ce-aa19-737985bbd819",
                    "name": "test1"
                }
            ],
            "id": "recipe_06bdb3ec-6168-4934-b4e7-1830c38c9294",
            "type": "light",
            "lastModified": "2024-02-18T02:56:55.612Z",
            "lastModifiedBy": "admin",
            "state": "active",
            "modified": false,
            "config": {
                "settlementPeriod": 1800,
                "settlementsPerDay": 48,
                "defaultStartSettlement": 47,
                "totalMolesPerDayMinThreshold": 0.25,
                "totalMolesPerDayMaxThreshold": 20,
                "moleChangePerDayMin": -0.02,
                "moleChangePerDayMax": 0.02,
                "lightIntensityPerSettlementPeriodMin": 0.3,
                "lightIntensityPerSettlementPeriodMax": 0.8,
                "intensityChangePerSettlementPeriodMin": -0.3,
                "intensityChangePerSettlementPeriodMax": -0.3,
                "lineType": "double",
                "lookupTableName": "testlookuptable"
            },
            "settlements": [
                {
                    "slot": 1,
                    "settlementType": "dawn",
                    "values": {
                        "intensity": 0.3
                    },
                    "_id": "65d171f74c1eb4d55ec963d3"
                },
            ],
            "lastProcessed": "",
            "lastPushed": "",
            "showEdit": true,
            "showDelete": true,
            "created": "2024-02-18T02:56:55.612Z",
            "createdBy": "admin"
        }
 */

export default function BasicTable() {
	const navigate = useNavigate();

	const [isBase, setIsBase] = React.useState(true);
	const [isModified, setIsModified] = React.useState(true);

	const [triggerGetRecipes, result] = useLazyGetRecipesQuery();
	const [deleteRecipe, { isLoading, isFetching }] = useDeleteRecipeMutation();

	const { currentData: { recipes = [] } = {} } = result ?? {};

	const [currentPage, entriesPerPage, render, setTotal] = usePagination(0);

	React.useEffect(() => {
		getRecipes();
	}, [currentPage, entriesPerPage]);

	async function getRecipes(base = isBase, modified = isModified) {
		const { data } = await triggerGetRecipes({
			count: entriesPerPage,
			page: currentPage,
			include_setting: base && modified ? 3 : base ? 1 : modified ? 2 : 0,
		});
		const { totalCount = 0 } = data ?? {};
		setTotal(Number(totalCount));
	}

	function onSelectRecipe(recipe) {
		console.log("onselect", recipe);
		navigate("/recipes/" + recipe.id);
	}

	async function onClickDelete(recipe) {
		const { isConfirmed } = await deleteModal({
			title: `Delete recipe: ${recipe?.name}`,
		});
		if (isConfirmed) {
			if (recipe?.id) {
				const { error } = await deleteRecipe({
					recipeId: recipe.id,
				});
				if (error == undefined) {
					successModal({
						message: `Recipe: ${recipe?.name}  deleted successfully`,
					});
					getRecipes();
				}
			}
		}
	}

	function getActionButtons(recipe) {
		const { showEdit = false, showDelete = false } = recipe;
		return (
			<Stack direction={"row"} spacing={0.5}>
				{showEdit ? (
					<EditIcon
						className='edit-icon'
						onClick={() => navigate("/recipes/" + recipe.id + "/edit")}
					/>
				) : null}
				{showDelete ? (
					<>
						<Divider orientation='vertical' flexItem />
						<DeleteIcon
							className='delete-icon'
							onClick={() => onClickDelete(recipe)}
						/>
					</>
				) : null}
				<Divider orientation='vertical' flexItem />
				<CopyAllIcon
					className='copy-icon'
					onClick={() => navigate("/recipes/" + "create", { state: recipe })}
				/>
			</Stack>
		);
	}

	return (
		<>
			{isLoading || isFetching ? <Loader /> : null}
			<Loader open={result.isLoading || result.isFetching} />
			<Stack flexDirection={"row"} gap={2}>
				<Button
					variant='contained'
					component={Link}
					to='/recipes/create'
					className='btn'
					startIcon={<AddIcon />}>
					Create Recipe
				</Button>
				<Button
					variant='contained'
					component={Link}
					to='/recipes/reciperoomlink'
					className='btn'
					startIcon={<AddIcon />}
					style={{ margin: 0 }}>
					Link Recipe To Room
				</Button>
				<Button
					variant='contained'
					component={Link}
					to='/recipes/manageLookupTables'
					className='btn'
					startIcon={<AddIcon />}
					style={{ margin: 0 }}>
					Manage Lookups
				</Button>
			</Stack>
			<FormGroup row aria-label='filter recipes'>
				<FormControlLabel
					control={
						<Checkbox
							checked={isBase}
							name='base'
							onChange={() => {
								if (isBase) {
									setIsBase(false);
									getRecipes(false, isModified);
								} else {
									setIsBase(true);
									getRecipes(true, isModified);
								}
							}}
						/>
					}
					label='Show Base Recipes'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={isModified}
							onChange={() => {
								if (isModified) {
									setIsModified(false);
									getRecipes(isBase, false);
								} else {
									setIsModified(true);
									getRecipes(isBase, true);
								}
							}}
							name='modified'
						/>
					}
					label='Show Modified Recipes'
				/>
			</FormGroup>
			<TableContainer className='recipes-table card data-table'>
				<Table sx={{ minWidth: 650 }} aria-label='recipes table'>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: "100px" }}>Action</TableCell>
							<TableCell sx={{ width: "50px" }}>Active</TableCell>
							<TableCell>Name</TableCell>
							<TableCell sx={{ width: "50px" }}>Type</TableCell>
							<TableCell
								sx={{
									margin: 0,
									width: "50px",
									padding: "1px 8px !important",
								}}>
								Base Recipe?
							</TableCell>
							<TableCell>Location</TableCell>
							<TableCell sx={{ width: "120px" }}>Created Date</TableCell>
							<TableCell sx={{ width: "120px" }}>Modified Date</TableCell>
							<TableCell sx={{ width: "120px" }}>Last Processed Date</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{recipes.map((recipe, index) => {
							const {
								state = "",
								name = "",
								type = "",
								modified = false,
								rooms = [],
								created = "",
								lastModified = "",
								lastProcessed = "",
							} = recipe;
							return (
								<TableRow key={index + recipe.id} className='row'>
									<TableCell align='left' sx={{ width: "100px" }}>
										{getActionButtons(recipe)}
									</TableCell>
									<TableCell
										align='left'
										className='hover'
										onClick={() => onSelectRecipe(recipe)}
										sx={{ width: "50px" }}>
										{state?.toLowerCase() == "active" ? "YES" : "NO"}
									</TableCell>
									<TableCell
										className='hover'
										onClick={() => onSelectRecipe(recipe)}>
										{name}
									</TableCell>
									<TableCell
										className='hover'
										onClick={() => onSelectRecipe(recipe)}
										sx={{ width: "50px" }}>
										{type}
									</TableCell>
									<TableCell
										className='hover'
										onClick={() => onSelectRecipe(recipe)}>
										{!modified ? "Yes" : "No"}
									</TableCell>
									<TableCell
										className='hover'
										onClick={() => onSelectRecipe(recipe)}>
										{rooms.map(({ name = "" }) => name).join(",")}
									</TableCell>
									<TableCell
										className='hover'
										onClick={() => onSelectRecipe(recipe)}
										sx={{ width: "120px" }}>
										{formatDate(created)}
									</TableCell>
									<TableCell
										className='hover'
										onClick={() => onSelectRecipe(recipe)}
										sx={{ width: "120px" }}>
										{formatDate(lastModified)}
									</TableCell>
									<TableCell
										className='hover'
										onClick={() => onSelectRecipe(recipe)}
										sx={{ width: "120px" }}>
										{formatDate(lastProcessed)}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				{render()}
			</TableContainer>
		</>
	);
}
